import { Button } from 'components/button/Button'
import { useCreateDirectiveLogic } from 'components/directive/utils/directiveUtils'
import { HStack } from 'components/Stack'
import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { matchMap, toLowerCase } from 'utils/general'
import { BREAKPOINTS } from 'utils/styled'

export const EstatePlanningHeroButtons: FC = () => {
  return (
    <HStack
      gap={['0.75rem', '1.5rem']}
      css={BREAKPOINTS.LG.css({
        '& > *': { flexBasis: '50%' },
      })}
    >
      <DirectiveActionButton directive="WILL" />
      <DirectiveActionButton directive="TRUST" />
    </HStack>
  )
}

export const DirectiveActionButton: FC<{ directive: SimpleFlowDirectiveType }> = ({
  directive,
}) => {
  const { t } = useTranslation('estate_planning_lp')
  const {
    cta: createCta,
    create,
    existingDirective: existingWill,
  } = useCreateDirectiveLogic(directive, { place: 'homepage' })

  const cta = existingWill ? createCta : undefined

  return (
    <Button
      type="button"
      size={'large'}
      onClick={create}
      tone={matchMap(directive, { TRUST: 'trust', WILL: 'standard' } as const)}
    >
      {cta ?? t(`estate_planning_lp.hero.start_${toLowerCase(directive)}`)}
    </Button>
  )
}
