import styled from '@emotion/styled'
import { ContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import { EstatePlanningHeroButtons } from 'components/cornerstone/estatePlanning/buttons/EstatePlanningHeroButtons'
import { GoodTrustReferences } from 'components/estatePlanning/landing/references/GoodTrustReferences'
import { ContentfulImage } from 'components/Image'
import { LandingPageHero } from 'components/landingPageHero/LandingPageHero'
import { LandingPageTextImagePerks } from 'components/landingPageTextImageSection/LandingPageTextImagePerks'
import { Spacer } from 'components/spacer/Spacer'
import { Stack } from 'components/Stack'
import { Trans, useTranslation } from 'next-i18next'
import { FC } from 'react'
import { colors } from 'utils/colors'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'
import { numberRange } from 'utils/general'
import { useWindowSize } from 'utils/hooks'
import { mapKeysToTrans } from 'utils/i18n/mapKeysToTrans'
import { BREAKPOINTS } from 'utils/styled'

export type EstatePlanningHeroProps = {
  mediaMentions: ContentfulMediaMentions
}

export const EstatePlanningHero: FC<EstatePlanningHeroProps> = (props) => {
  const { t } = useTranslation('estate_planning_lp')
  const images = useContentfulImages()
  const { isXLarge } = useWindowSize()

  function getTitleFor(type: 'desktop' | 'mobile') {
    return (
      <Trans
        t={t}
        i18nKey={
          type === 'desktop'
            ? 'estate_planning_lp.hero.title'
            : 'estate_planning_lp.hero.title_mobile'
        }
      />
    )
  }

  return (
    <LandingPageHero
      title={getTitleFor('desktop')}
      image={<ContentfulImage {...images.estate_planning_hero_pivot} priority quality={50} />}
      tone="light-blue"
      heroCSS={{ gridColumnGap: '3rem', paddingTop: '3.5rem' }}
      videoButton="estate_planning"
      overflowing={!isXLarge}
      mobileVariant="modern"
    >
      <HeroPerks />

      <Spacer size={2} />

      <EstatePlanningHeroButtons />

      <Spacer size={3} />

      <GoodTrustReferences
        mediaMentions={props.mediaMentions}
        caption={<Trans t={t} i18nKey="estate_planning_lp.references.caption" />}
      />
    </LandingPageHero>
  )
}

const HeroPerks: FC = () => {
  const { t } = useTranslation('estate_planning_lp')
  const heroPerks = mapKeysToTrans(
    t,
    numberRange(4).map((i) => `estate_planning_lp.hero.perk_${i}` as const)
  )

  return <LandingPageTextImagePerks iconColor={colors.blue[500]} perks={heroPerks} simple />
}

export const SStack = styled(Stack)`
  @media ${BREAKPOINTS.MD.min} {
    align-items: center;
  }
`
