import { contentful } from 'api/contentful/api'
import { getContentfulFAQ } from 'api/contentful/getContentfulFAQ'
import { getContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import {
  EstatePlanningLP,
  EstatePlanningLPProps,
} from 'components/estatePlanning/landing/EstatePlanningLP'
import { GetStaticProps } from 'next'
import { REVALIDATE_SECS } from 'utils/constants'
import { getPageArticles } from 'utils/contentful/getPageArticles'
import { getContentfulImages, getContentfulVideoIds } from 'utils/contentful/getPageImages'
import { getEstatePlanningPrice } from 'utils/getInitialEstatePlanningPrice'
import { getIntlProps } from 'utils/i18n/getIntlProps'
import { getContentfulMentions } from 'api/contentful/getContentfulMentions'

export default EstatePlanningLP

export const getStaticProps: GetStaticProps<EstatePlanningLPProps> = async (ctx) => {
  const mediaMentions = await getContentfulMediaMentions('global')

  const mentions = await getContentfulMentions('desktop')
  const faq = await getContentfulFAQ('estate-planning')

  if (!mediaMentions) throw Error('Could not fetch Estate Planning page content.')

  const { desktop, mobile } = await contentful.getHomepageSetup().then((setup) => {
    return { desktop: setup.desktop?.items[0], mobile: setup.mobile?.items[0] }
  })

  const estatePlanningPrice = await getEstatePlanningPrice()

  const articles = await getPageArticles('ESTATE_PLANNING')

  if (!desktop || !mobile) {
    throw Error('Could not get homepage setup from contentful.')
  }

  const props: EstatePlanningLPProps = {
    ...(await getContentfulImages()),
    ...(await getContentfulVideoIds()),
    ...(await getIntlProps(ctx, ['estate_planning_lp', 'home'])),
    estatePlanningPrice,
    mediaMentions,
    mentions,
    faq,
    articles,
    content: desktop,
  }

  return { props, revalidate: REVALIDATE_SECS }
}
