import { Button } from 'components/button/Button'
import { Icon } from 'components/icon'
import { ContentfulImage } from 'components/Image'
import { PerksList } from 'components/perksList/PerksList'
import { Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { startDirectiveFlow } from 'logic/directive/create/start'
import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { describeEstatePlanningLandingPage } from 'logic/estatePlanning/landing/describe'
import { Trans, useTranslation } from 'next-i18next'
import { FC } from 'react'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { colors } from 'utils/colors'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'
import { toLowerCase } from 'utils/general'
import { BREAKPOINTS } from 'utils/styled'

export const DirectiveCard: FC<{
  directive: SimpleFlowDirectiveType
}> = ({ directive }) => {
  const { isLogged } = useAuth()
  const { t } = useTranslation('estate_planning_lp', {
    keyPrefix: `estate_planning_lp.section_comparison.${toLowerCase(directive)}`,
  })

  const images = useContentfulImages()
  const desc = describeEstatePlanningLandingPage().forDirective(directive)
  const directiveDesc = desc.asDirective()

  const tone = directive === 'TRUST' ? 'trust' : 'standard'

  return (
    <Stack
      css={[
        {
          background: 'white',
          boxShadow: 'var(--card-shadow)',
          width: '25rem',
          paddingTop: '2rem',
        },
        BREAKPOINTS.MD.css({
          width: '100%',
          paddingTop: '0.5rem',
        }),
      ]}
      gap={['0.5rem', '1rem']}
    >
      <Text variant="subtitleBold" color={colors.black}>
        <Trans t={t} i18nKey="title" />
      </Text>

      <ContentfulImage
        {...images[`estate_planning_section_comparison_${toLowerCase(directive)}`]}
      />

      <Stack
        gap={['0.5rem', '1rem']}
        css={[
          { padding: '0 1.5rem 1.5rem', textAlign: 'left' },
          BREAKPOINTS.MD.css({ paddingBottom: '1rem' }),
        ]}
      >
        <PerksList
          titleOnly
          icon={
            <Icon
              name="CheckSimple"
              color={directive === 'TRUST' ? colors.green[200] : colors.blue[500]}
            />
          }
          perks={desc.getComparisonPerks(t)}
        />
        <Button
          type="button"
          size="large"
          block
          tone={tone}
          onClick={() =>
            startDirectiveFlow(directive, {
              isLogged: !!isLogged,
              fromInside: false,
              routerMechanism: 'push',
              simpleFlowPlace: 'homepage',
            })
          }
        >
          <Trans t={t} i18nKey="start_button" />
        </Button>

        <Button type="button" variant="text" tone={tone} href={directiveDesc.getLandingPageUrl()}>
          <Trans t={t} i18nKey="more_button" />
        </Button>
      </Stack>
    </Stack>
  )
}
