import { IArticlesSectionProps } from 'components/articlesSection/ArticlesSection'
import { IEstatePlanningLPSection } from 'components/cornerstone/estatePlanning/EstatePlanningLPSection'
import { IEstatePlanningSignupSectionProps } from 'components/estatePlanning/landing/signup/EstatePlanningSignupSection'
import { FAQuestionsProps } from 'components/faqTwoColumn/types'
import { IFooterProps } from 'components/footer/Footer'
import { IStatisticsProps } from 'components/statistics/types'
import dynamic from 'next/dynamic'
import { EstatePlanningPriceType } from 'utils/getInitialEstatePlanningPrice'
import { HorizontalPlacement } from 'utils/types'

export const DynamicWillTrustComparisonSection = dynamic<{
  estatePlanningPrice: EstatePlanningPriceType
}>(
  () =>
    import('components/estatePlanning/landing/comparison/WillTrustComparisonSection').then(
      (module) => module.WillTrustComparisonSection
    ),
  { ssr: false }
)
export const DynamicEstatePlanningLPSection = dynamic<IEstatePlanningLPSection>(
  () =>
    import('components/cornerstone/estatePlanning/EstatePlanningLPSection').then(
      (module) => module.EstatePlanningLPSection
    ),
  { ssr: false }
)
export const DynamicStatistics = dynamic<IStatisticsProps>(
  () => import('components/statistics/Statistics').then((module) => module.Statistics),
  { ssr: false }
)
export const DynamicFaqTwoColumns = dynamic<FAQuestionsProps>(
  () => import('components/faqTwoColumn/FaqTwoColumn').then((module) => module.FaqTwoColumns),
  { ssr: false }
)
export const DynamicArticlesSection = dynamic<IArticlesSectionProps>(
  () =>
    import('components/articlesSection/ArticlesSection').then((module) => module.ArticlesSection),
  { ssr: false }
)
export const DynamicSecuritySection = dynamic<{
  imagePlacement?: HorizontalPlacement
}>(() => import('components/security/SecuritySection').then((module) => module.SecuritySection), {
  ssr: false,
})
export const DynamicSupportSection = dynamic(
  () => import('components/supportSection/SupportSection').then((module) => module.SupportSection),
  { ssr: false }
)
export const DynamicEstatePlanningSignupSection = dynamic<IEstatePlanningSignupSectionProps>(
  () =>
    import('components/estatePlanning/landing/signup/EstatePlanningSignupSection').then(
      (module) => module.EstatePlanningSignupSection
    ),
  { ssr: false }
)

export const DynamicFooter = dynamic<IFooterProps>(
  () => import('components/footer/Footer').then((module) => module.Footer),
  { ssr: false }
)
