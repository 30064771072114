import { Perk } from 'components/perkItem/PerkItem'
import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { TFunction } from 'react-i18next'
import { numberRange } from 'utils/general'

export function getComparisonPerks(
  directive: SimpleFlowDirectiveType,
  t: TFunction<
    'estate_planning_lp',
    'estate_planning_lp.section_comparison.will' | 'estate_planning_lp.section_comparison.trust'
  >
) {
  return numberRange(3).map((i): Perk => ({ title: t(`perk_${i}`) }))
}
