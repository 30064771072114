import { ArticleFragment, HomePageSetupFragment } from 'api/contentful/contentful.graphql.types'
import { ContentfulFAQ } from 'api/contentful/getContentfulFAQ'
import { ContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import { ArticlesSection } from 'components/articlesSection/ArticlesSection'
import { Block } from 'components/Block'
import { EstatePlanningHero } from 'components/cornerstone/estatePlanning/EstatePlanningHero'
import { EstatePlanningLPSection } from 'components/cornerstone/estatePlanning/EstatePlanningLPSection'
import { WillTrustComparisonSection } from 'components/estatePlanning/landing/comparison/WillTrustComparisonSection'
import { DynamicFooter } from 'components/estatePlanning/landing/EstatePlanningLP/dynamicImports'
import { EstatePlanningSignupSection } from 'components/estatePlanning/landing/signup/EstatePlanningSignupSection'
import { FaqTwoColumns } from 'components/faqTwoColumn/FaqTwoColumn'
import { TopNavigationLandingPage } from 'components/header/topNavigationLandingPage/TopNavigationLandingPage'
import { Layout } from 'components/layout/Layout'
import { PageMeta } from 'components/PageMeta'
import { PageTitle } from 'components/PageTitle'
import { SecuritySection } from 'components/security/SecuritySection'
import { Stack } from 'components/Stack'
import { Statistics } from 'components/statistics/Statistics'
import { SupportSection } from 'components/supportSection/SupportSection'
import { TestimonialsSection } from 'components/testimonialsSection/TestimonialsSection'
import { describeEstatePlanningLandingPage } from 'logic/estatePlanning/landing/describe'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { cobrandingLogic } from 'utils/cobranding/logic'
import { EstatePlanningPriceType } from 'utils/getInitialEstatePlanningPrice'
import { useRunOnceReady } from 'utils/hooks'
import { ContentfulMentions } from 'api/contentful/getContentfulMentions'

export type EstatePlanningLPProps = {
  content: HomePageSetupFragment
  mediaMentions: ContentfulMediaMentions
  mentions: ContentfulMentions
  faq: ContentfulFAQ
  estatePlanningPrice: EstatePlanningPriceType
  articles: ArticleFragment[]
}

export const EstatePlanningLP: FC<EstatePlanningLPProps> = (props) => {
  const { t } = useTranslation('estate_planning_lp')
  const router = useRouter()
  const { getStatisticItems } = describeEstatePlanningLandingPage()

  useRunOnceReady(!!router.query.promo, () => {
    cobrandingLogic.onEstatePlanningLPMounted({ promoQuery: router.query.promo })
  })

  const mediaMentions = { items: props.mediaMentions?.items.slice(0, 5) ?? [] }
  return (
    <Layout
      header={<TopNavigationLandingPage variant="estate-planning" noSpacingBelow />}
      footer={<DynamicFooter variant="estate-planning" />}
    >
      <PageTitle title={t('estate_planning_lp.meta_title')} />
      <PageMeta
        description={t('estate_planning_lp.meta_description')}
        image="/images/preview/estate-planning.png"
      />

      <Stack gap={['2rem', '6rem']}>
        <EstatePlanningHero mediaMentions={mediaMentions} />

        <Block>
          <WillTrustComparisonSection estatePlanningPrice={props.estatePlanningPrice} />
        </Block>

        <EstatePlanningLPSection section="simplicity" />
        <EstatePlanningLPSection section="standards" imageRight />

        <Block>
          <Statistics
            title={t('estate_planning_lp.statistics.title')}
            items={getStatisticItems()}
            tone="standard"
          />
        </Block>

        <EstatePlanningLPSection section="vault" imageRight />

        <TestimonialsSection
          mediaMentions={mediaMentions}
          mentions={props.mentions}
          responsiveItemCounts={[5, 5, 5]}
        />

        <SecuritySection />

        <SupportSection />

        <EstatePlanningLPSection section="accessible" />

        <ArticlesSection
          title={t('estate_planning_lp.articles.title')}
          caption={t('estate_planning_lp.articles.caption')}
          description={t('estate_planning_lp.articles.description')}
          articles={props.articles}
        />

        <FaqTwoColumns content={props.faq} />

        <EstatePlanningSignupSection mediaMentions={mediaMentions} place="homepage" />
      </Stack>
    </Layout>
  )
}
