import { DirectiveCard } from 'components/estatePlanning/landing/comparison/DirectiveCard'
import { PrimaryLink } from 'components/Link'
import { Spacer } from 'components/spacer/Spacer'
import { Stack } from 'components/Stack'
import { Text, Title, Title_h4_default } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import { FC } from 'react'
import { colors } from 'utils/colors'
import { TRUST_VS_WILL_URL } from 'utils/constants'
import { EstatePlanningPriceType } from 'utils/getInitialEstatePlanningPrice'
import { useWindowSize } from 'utils/hooks'
import { useEstatePlanPrice } from 'utils/promo'

export const WillTrustComparisonSection: FC<{
  estatePlanningPrice: EstatePlanningPriceType
}> = ({ estatePlanningPrice }) => {
  const { t: tCommon } = useTranslation('common')
  const { t } = useTranslation('estate_planning_lp', {
    keyPrefix: 'estate_planning_lp.section_comparison',
  })
  const { isMobile } = useWindowSize()

  const estatePlanningPriceForUser = useEstatePlanPrice(estatePlanningPrice)
  const { type: discountType } = estatePlanningPriceForUser

  return (
    <Stack css={{ textAlign: 'center', alignItems: 'center' }}>
      <Stack gap="1rem" css={{ maxWidth: '52rem' }}>
        <Title variant={Title_h4_default}>
          <Trans t={t} i18nKey="title" />
        </Title>
        <Text variant="subtitleBold" color={colors.black}>
          {discountType === 'initial-no-discount' ? (
            <Trans
              t={t}
              i18nKey="subtitle"
              values={{ price: estatePlanningPriceForUser.undiscountedPriceText }}
            />
          ) : discountType === 'initial-discount' ||
            discountType === 'initial-free-subscription' ? (
            <Trans
              t={t}
              i18nKey="subtitle_discount"
              values={{
                initialPrice: estatePlanningPriceForUser.undiscountedPriceText,
                discountedPrice:
                  discountType === 'initial-free-subscription'
                    ? tCommon('common.free')
                    : estatePlanningPriceForUser.discountedText,
              }}
              components={[
                <Text
                  key={0}
                  variant="subtitleBold"
                  css={{ textDecoration: 'line-through' }}
                  color={colors.black}
                />,
                <Text key={1} variant="subtitleBold" color={colors.black} />,
              ]}
            />
          ) : null}
        </Text>
        <Text>
          <Trans t={t} i18nKey="description" context={isMobile ? 'mobile' : undefined} />
        </Text>
      </Stack>

      <Spacer size={[1.5, 3]} />

      <Stack gap={['1rem', '2rem']} orientation={['vertical', 'horizontal']}>
        <DirectiveCard directive="WILL" />
        <DirectiveCard directive="TRUST" />
      </Stack>

      <Spacer size={[1.5, 3]} />

      <Text>
        <Trans
          t={t}
          i18nKey="helpful_note"
          components={[
            <PrimaryLink key={0} href={TRUST_VS_WILL_URL} />,
            <PrimaryLink key={1} href="/pricing" />,
          ]}
        />
      </Text>
    </Stack>
  )
}
