import { IconName } from 'components/icon'
import { numberRange } from 'utils/general'
import { NumberRange } from 'utils/types'
import { Translation } from 'next-i18next'

export function getStatisticItems() {
  return numberRange(3).map((i) => ({
    title: (
      <Translation ns="estate_planning_lp">
        {(t) => t(`estate_planning_lp.statistics.item_${i}.title`)}
      </Translation>
    ),
    description: (
      <Translation ns="estate_planning_lp">
        {(t) => t(`estate_planning_lp.statistics.item_${i}.desc`)}
      </Translation>
    ),
    icon: statisticIcons[i],
  }))
}

const statisticIcons: Record<NumberRange<3>, IconName> = {
  '1': 'MoneyBag',
  '2': 'Will',
  '3': 'DocumentReady',
}
