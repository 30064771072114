import { Button } from 'components/button/Button'
import { ButtonProps } from 'components/button/types'
import {
  ThemedTextImageSection,
  ThemedTextImageSectionProps,
} from 'components/content/themedTextImageSection/ThemedTextImageSection'
import { ContentfulImage } from 'components/Image'
import { Text } from 'components/Typography'
import { startDirectiveFlow } from 'logic/directive/create/start'
import { EstatePlanningLandingSection } from 'logic/estatePlanning/landing/types'
import { useTranslation } from 'next-i18next'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'

export interface IEstatePlanningLPSection {
  section: EstatePlanningLandingSection
  extraProps?: ThemedTextImageSectionProps
  buttonProps?: Partial<ButtonProps>
  imageRight?: boolean
}

export const EstatePlanningLPSection = ({
  section,
  extraProps,
  buttonProps,
  imageRight,
}: IEstatePlanningLPSection) => {
  const { t } = useTranslation('estate_planning_lp')
  const images = useContentfulImages()
  const { isLogged } = useAuth()

  return (
    <ThemedTextImageSection
      caption={t(`estate_planning_lp.section_${section}.caption`).trim() || undefined}
      title={t(`estate_planning_lp.section_${section}.title`)}
      image={<ContentfulImage {...images[`estate_planning_section_${section}`]} />}
      imagePlacement={imageRight ? 'right' : 'left'}
      {...extraProps}
    >
      <Text>{t(`estate_planning_lp.section_${section}.desc`)}</Text>
      <Button
        type="button"
        variant="primary"
        size="large"
        css={{ alignSelf: 'start' }}
        onClick={() => {
          startDirectiveFlow('not_yet_chosen', {
            isLogged: !!isLogged,
            routerMechanism: 'push',
            simpleFlowPlace: 'homepage',
          })
        }}
        {...buttonProps}
      >
        {buttonProps?.children ?? t(`estate_planning_lp.section_${section}.cta`)}
      </Button>
    </ThemedTextImageSection>
  )
}
