import { describeDirective } from 'logic/directive/describe'
import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { getComparisonPerks } from 'logic/estatePlanning/landing/getComparisonPerks'
import { getStatisticItems } from 'logic/estatePlanning/landing/getStatisticItems'
import { ParametersExceptFirst } from 'utils/types'

export function describeEstatePlanningLandingPage() {
  return {
    getStatisticItems,
    forDirective: describeEstatePlanningLandingPageForDirective,
  }
}

function describeEstatePlanningLandingPageForDirective(directive: SimpleFlowDirectiveType) {
  return {
    getComparisonPerks: (...args: ParametersExceptFirst<typeof getComparisonPerks>) =>
      getComparisonPerks(directive, ...args),
    asDirective: () => describeDirective(directive),
  }
}
